import {
  createI18n,
  Link,
  NavLink,
  Redirect,
} from './components/I18n';

export {
  createI18n,
  Link,
  NavLink,
  Redirect,
};
